import { UserType } from 'shared-general-libs/type/user';
import { RoutesConfig } from '@ieApp/routes.config';
import { NavItem } from '@ieShared/interfaces/nav-bar.interface';
import {
  PartnerPermissions,
  Permission,
} from 'shared-general-libs/enum/permission';

export const enum PAGES {
  DASHBOARD = 'DASHBOARD',
  TASKS = 'TASKS',
  ACCOUNTING = 'ACCOUNTING',
  MATERIALITY = 'MATERIALITY',
  COLLECT = 'COLLECT',
  REVIEW = 'REVIEW',
  REPORT = 'REPORT',
  SETTINGS = 'SETTINGS',
  DATA_REQUESTS = 'DATA_REQUESTS',
  APPLICATIONS = 'APPLICATIONS',
  CERTIFICATIONS = 'CERTIFICATIONS',
  AUDITS = 'AUDITS',
}

export function getEnterprisePagePermissions(page: PAGES) {
  return ENTERPRISE_MENU.find((item) => item.id === page)?.permission || [];
}

export function getPartnerPagePermissions(page: PAGES) {
  return PARTNER_MENU.find((item) => item.id === page)?.permission || [];
}

const ENTERPRISE_MENU: NavItem[] = [
  {
    id: PAGES.TASKS,
    label: 'NAV_MENU.MY_TASKS',
    icon: 'task',
    route: RoutesConfig.enterpriseMyTasks,
    permission: [Permission.Idr.VIEW_LIST],
    exact: false,
  },
  {
    id: PAGES.ACCOUNTING,
    label: 'NAV_MENU.ACCOUNTING',
    icon: 'activity',
    route: RoutesConfig.enterpriseAccounting,
    permission: [Permission.Accounting.VIEW],
    exact: false,
  },
  {
    id: PAGES.MATERIALITY,
    label: 'NAV_MENU.MATERIALITY',
    route: RoutesConfig.enterpriseMateriality,
    permission: [Permission.Survey.VIEW],
    icon: 'gps',
    exact: false,
  },
  {
    id: PAGES.COLLECT,
    label: 'NAV_MENU.COLLECT',
    icon: '3square',
    route: RoutesConfig.enterpriseCollect,
    permission: [Permission.Collect.VIEW],
    exact: false,
  },
  {
    id: PAGES.REVIEW,
    label: 'NAV_MENU.REVIEW',
    icon: 'review',
    route: RoutesConfig.enterpriseReview,
    permission: [Permission.Idr.VIEW_REVIEW],
    exact: false,
  },
  {
    id: PAGES.REPORT,
    label: 'NAV_MENU.REPORT',
    icon: 'report',
    route: RoutesConfig.enterpriseReport,
    permission: [Permission.Report.VIEW],
    exact: false,
  },
  {
    id: PAGES.SETTINGS,
    label: 'NAV_MENU.SETTINGS',
    icon: 'setting-2',
    route: RoutesConfig.enterpriseSettings,
    permission: [
      ...Object.values(Permission.Organization),
      ...Object.values(Permission.User),
      ...Object.values(Permission.Facility),
      ...Object.values(Permission.Partner),
      ...Object.values(Permission.Equipment),
      ...Object.values(Permission.EmissionFactor),
      ...Object.values(Permission.Role),
      ...Object.values(Permission.Topic),
    ],
    exact: false,
  },
];

const PARTNER_MENU: NavItem[] = [
  {
    id: PAGES.DATA_REQUESTS,
    label: 'NAV_MENU.DATA_REQUESTS',
    icon: 'home',
    route: RoutesConfig.partnerDataRequests,
    permission: [PartnerPermissions.PartnerRequest.VIEW],
    exact: false,
  },
  {
    id: PAGES.SETTINGS,
    label: 'NAV_MENU.SETTINGS',
    icon: 'setting-2',
    route: RoutesConfig.partnerSettings,
    permission: [PartnerPermissions.Partner.VIEW],
    exact: false,
  },
];

const PORTFOLIO_USER: NavItem[] = [
  // {
  //   label: 'NAV_MENU.DASHBOARD',
  //   icon: 'home',
  //   route: RoutesConfig.portfolioDashboard,
  //   permission: [],
  //   exact: true,
  // },
  {
    id: PAGES.DATA_REQUESTS,
    label: 'NAV_MENU.DATA_REQUESTS',
    icon: 'task',
    route: RoutesConfig.portfolioDataRequests,
    permission: [],
    exact: true,
  },
];

const CERTIFIER_USER: NavItem[] = [
  // {
  //   label: 'NAV_MENU.DASHBOARD',
  //   icon: 'home',
  //   route: RoutesConfig.certifierDashboard,
  //   permission: [],
  //   exact: true,
  // },
  {
    id: PAGES.APPLICATIONS,
    label: 'NAV_MENU.APPLICATIONS',
    icon: 'home',
    permission: [],
    opened: false,
    children: [
      {
        label: 'NAV_MENU.ASSIGN',
        route: RoutesConfig.certifierAssign,
        permission: [],
        exact: false,
      },
      {
        label: 'NAV_MENU.VERIFY',
        route: RoutesConfig.certifierVerify,
        permission: [],
        exact: false,
      },
      {
        label: 'NAV_MENU.REVIEW',
        route: RoutesConfig.certifierReview,
        permission: [],
        exact: false,
      },
    ],
  },
  {
    id: PAGES.CERTIFICATIONS,
    label: 'NAV_MENU.CERTIFICATIONS',
    icon: 'home',
    route: RoutesConfig.certifierCertifications,
    permission: [],
    exact: true,
  },
  {
    id: PAGES.AUDITS,
    label: 'NAV_MENU.AUDITS',
    icon: 'medal-star',
    route: RoutesConfig.certifierAudits,
    permission: [],
    exact: true,
  },
];

const AUDITOR_USER: NavItem[] = [
  // {
  //   label: 'NAV_MENU.DASHBOARD',
  //   icon: 'home',
  //   route: RoutesConfig.auditorDashboard,
  //   permission: [],
  //   exact: true,
  // },
  {
    id: PAGES.APPLICATIONS,
    label: 'NAV_MENU.APPLICATIONS',
    icon: 'home',
    opened: false,
    permission: [],
    children: [
      {
        label: 'NAV_MENU.ASSIGN',
        route: RoutesConfig.auditorAssign,
        permission: [],
        exact: false,
      },
      {
        label: 'NAV_MENU.VERIFY',
        route: RoutesConfig.auditorVerify,
        permission: [],
        exact: false,
      },
      {
        label: 'NAV_MENU.REVIEW',
        route: RoutesConfig.auditorReview,
        permission: [],
        exact: false,
      },
    ],
  },
  {
    id: PAGES.CERTIFICATIONS,
    label: 'NAV_MENU.CERTIFICATIONS',
    icon: 'home',
    route: RoutesConfig.auditorCertifications,
    permission: [],
    exact: true,
  },
  {
    id: PAGES.AUDITS,
    label: 'NAV_MENU.AUDITS',
    icon: 'medal-star',
    route: RoutesConfig.auditorAudits,
    permission: [],
    exact: true,
  },
];

const SUPER_ADMIN: NavItem[] = [
  {
    id: PAGES.SETTINGS,
    label: 'NAV_MENU.SETTINGS',
    icon: 'setting-2',
    route: RoutesConfig.adminSettings,
    permission: [],
    exact: false,
  },
];

export const NAV_MENU = {
  [UserType.ENTERPRISE_USER]: ENTERPRISE_MENU,
  [UserType.ENTERPRISE_ADMIN]: ENTERPRISE_MENU,
  [UserType.PARTNER_USER]: PARTNER_MENU,
  [UserType.PORTFOLIO_USER]: PORTFOLIO_USER,
  [UserType.CERTIFIER_USER]: CERTIFIER_USER,
  [UserType.AUDITOR_USER]: AUDITOR_USER,
  [UserType.SUPER_ADMIN]: SUPER_ADMIN,
};
