import { RoutesConfigInterface } from '@ieShared/interfaces/routes-config.interface';

export const RoutesConfig: RoutesConfigInterface = {
  login: () => ['login'],
  forgotPassword: () => ['forgot-password'],
  confirmForgotPassword: (token = ':token') => [
    `forgot-password-confirm/${token}`,
  ],

  forcePasswordReset: () => ['force-password-reset'],

  surveyQuestionary: (type = ':type', formId = ':formId') => [
    'materiality-survey-answer',
    `${type}/${formId}`,
  ],

  notFound: () => ['not-found'],
  permissionDenied: () => ['permission-denied'],

  adminDashboard: () => ['admin'],
  adminAssign: () => ['admin', 'assign'],
  adminVerify: () => ['admin', 'verify'],
  adminReview: () => ['admin', 'review'],
  adminCertifications: () => ['admin', 'certifications'],
  adminAudits: () => ['admin', 'audits'],
  adminNotifications: () => ['admin', 'notifications'],
  adminCompanies: () => ['admin', 'companies'],
  adminNewCompany: () => ['admin', 'companies', 'new'],
  adminSettings: () => ['admin', 'settings'],
  adminOrganization: () => ['admin', 'settings', 'organization'],
  adminCompany: (organizationId = ':organizationId') => [
    'admin',
    'companies',
    organizationId,
  ],
  adminRoles: () => ['admin', 'settings', 'roles'],
  adminUsers: () => ['admin', 'settings', 'users'],
  adminTopics: (orgId = ':orgId') => [
    'admin',
    'settings',
    'organization',
    `topics/${orgId}`,
  ],
  adminEnums: (orgId = ':orgId') => [
    'admin',
    'settings',
    'organization',
    `enums/${orgId}`,
  ],
  adminNewRole: () => ['admin', 'settings', 'roles', 'new'],
  adminRole: (roleId = ':roleId', orgId = ':orgId') => [
    'admin',
    'settings',
    'roles',
    `${roleId}/${orgId}`,
  ],
  // Enterprise
  enterpriseDashboard: () => ['enterprise'],
  enterpriseMyTasks: () => ['enterprise', 'my-task'],
  enterpriseMyTasksDetails: (idrId = ':idrId') => [
    'enterprise',
    'my-task',
    `details/${idrId}`,
  ],
  enterpriseMyTasksPartnersQuestionnaire: (
    idrId = ':idrId',
    partnerId = ':partnerId',
    taskId = ':taskId',
  ) => ['enterprise', 'my-task', `details/${idrId}`, `${partnerId}/${taskId}`],

  enterpriseMyTasksPartnersQuestionnaireDetails: (
    idrId = ':idrId',
    partnerId = ':partnerId',
    taskId = ':taskId',
    topicId = ':topicId',
  ) => [
    'enterprise',
    'my-task',
    `details/${idrId}`,
    `${partnerId}/${taskId}/details/${topicId}`,
  ],

  enterpriseGoals: () => ['enterprise', 'plan/goals'],
  enterpriseCollect: () => ['enterprise', 'collect'],
  enterpriseCollectDetails: (dcrId = ':dcrId') => [
    'enterprise',
    'collect',
    `details/${dcrId}`,
  ],
  enterpriseCollectTaskView: (dcrId = ':dcrId', ownerId = ':ownerId') => [
    'enterprise',
    'collect',
    `details/${dcrId}`,
    `task-view/${ownerId}`,
  ],
  enterpriseCollectTaskViewDetails: (
    dcrId = ':dcrId',
    ownerId = ':ownerId',
    idrId = ':idrId',
  ) => [
    'enterprise',
    'collect',
    `details/${dcrId}`,
    `task-view/${ownerId}`,
    `task-view-details/${idrId}`,
  ],
  enterpriseReview: () => ['enterprise', 'review'],
  enterpriseReport: () => ['enterprise', 'report'],
  enterpriseReportDetails: (reportId = ':reportId') => [
    'enterprise',
    'report',
    reportId,
  ],
  enterpriseReviewDetails: (taskId = ':taskId') => [
    'enterprise',
    'review',
    `details/${taskId}`,
  ],
  enterpriseApplication: () => ['enterprise', 'audit/application'],
  enterpriseCertificates: () => ['enterprise', 'audit/certificates'],
  enterpriseWaste: () => ['enterprise', 'track/waste'],
  enterpriseProducts: () => ['enterprise', 'track/products'],
  enterpriseSettings: () => ['enterprise', 'settings'],
  enterpriseTopics: () => ['enterprise', 'settings', 'topics'],
  enterpriseRoles: () => ['enterprise', 'settings', 'roles'],
  enterpriseNewRole: () => ['enterprise', 'settings', 'roles', 'new'],
  enterpriseRole: (roleId = ':roleId') => [
    'enterprise',
    'settings',
    'roles',
    roleId,
  ],
  enterpriseCompany: () => ['enterprise', 'settings', 'company'],

  enterpriseEmissionFactors: () => [
    'enterprise',
    'settings',
    'emission-factors',
  ],
  enterpriseEmissionFactorsEnergy: () => [
    'enterprise',
    'settings',
    'emission-factors-energy',
  ],
  enterpriseEquipments: () => ['enterprise', 'settings', 'equipments'],
  enterpriseProductsAndServices: () => ['enterprise', 'settings', 'products'],
  enterpriseUsers: () => ['enterprise', 'settings', 'users'],
  enterpriseNotifications: () => ['enterprise', 'notifications'],
  enterprisePartner: (partnerId: string = ':partnerId') => [
    'enterprise',
    'settings',
    'partners',
    partnerId,
  ],
  enterprisePartners: () => ['enterprise', 'settings', 'partners'],
  enterpriseNewPartner: () => ['enterprise', 'settings', 'partner', 'new'],
  enterpriseFacilities: () => ['enterprise', 'settings', 'facilities'],
  enterpriseFacilityDetails: (facilityId: string = ':facilityId') => [
    'enterprise',
    'settings',
    'facilities',
    facilityId,
  ],
  enterpriseAccounting: () => ['enterprise', 'accounting'],
  enterpriseAccountingList: (topicKey: string = ':topicKey') => [
    'enterprise',
    'accounting',
    `list/${topicKey}`,
  ],
  enterpriseAccountingDetails: (
    topicKey: string = ':topicKey',
    dcId = ':dcId',
  ) => ['enterprise', 'accounting', `list/${topicKey}`, `details/${dcId}`],
  enterpriseMateriality: () => ['enterprise', 'materiality'],

  enterpriseMaterialityDetails: (surveyId: string = ':surveyId') => [
    'enterprise',
    `materiality`,
    `${surveyId}`,
  ],

  enterpriseContacts: () => ['enterprise', 'settings', 'contacts'],
  enterpriseContactDetails: (contactId: string = ':contactId') => [
    'enterprise',
    'settings',
    'contacts',
    contactId,
  ],
  // Partner
  partnerDashboard: () => ['partner'],
  partnerDataRequests: () => ['partner', 'data-request'],
  partnerDataRequestsDetails: (requestId = ':requestId') => [
    'partner',
    'data-request',
    `details/${requestId}`,
  ],
  partnerDataRequestsActivity: (
    requestId = ':requestId',
    activity = ':activity',
  ) => ['partner', 'data-request', `details/${requestId}`, `${activity}`],
  partnerDataRequestsPartnerActivity: (
    requestId = ':requestId',
    partnerId = ':partnerId',
    activity = ':activity',
  ) => [
    'partner',
    'data-request',
    `details/${requestId}`,
    `partner/${partnerId}/${activity}`,
  ],
  partnerWasteDataRequests: () => ['partner', 'track/west-data-request'],
  partnerNotifications: () => ['partner', 'notifications'],
  partnerSettings: () => ['partner', 'settings'],
  partnerUsers: () => ['partner', 'settings', 'users'],
  partnerProfile: () => ['partner', 'settings', 'profile'],
  partnerChangePassword: () => ['partner', 'settings', 'reset-password'],
  partnerPartners: () => ['partner', 'settings', 'partners'],
  partnerPartner: (partnerId: string = ':partnerId') => [
    'partner',
    'settings',
    'partners',
    partnerId,
  ],
  partnerMyTasksPartnersQuestionnaire: (
    idrId = ':idrId',
    partnerId = ':partnerId',
    taskId = ':taskId',
  ) => [
    'partner',
    'data-request',
    `questionnaire/${idrId}/${partnerId}/${taskId}`,
  ],
  partnerProducts: () => ['partner', 'settings', 'products'],

  // Portfolio
  portfolioDashboard: () => ['portfolio'],
  portfolioDataRequests: () => ['portfolio', 'data-requests'],
  portfolioNotifications: () => ['portfolio', 'notifications'],

  // Certifier
  certifierDashboard: () => ['certifier'],
  certifierAssign: () => ['certifier', 'assign'],
  certifierVerify: () => ['certifier', 'verify'],
  certifierReview: () => ['certifier', 'review'],
  certifierCertifications: () => ['certifier', 'certifications'],
  certifierAudits: () => ['certifier', 'audits'],
  certifierNotifications: () => ['certifier', 'notifications'],

  // Auditor
  auditorDashboard: () => ['auditor'],
  auditorAssign: () => ['auditor', 'assign'],
  auditorVerify: () => ['auditor', 'verify'],
  auditorReview: () => ['auditor', 'review'],
  auditorCertifications: () => ['auditor', 'certifications'],
  auditorAudits: () => ['auditor', 'audits'],
  auditorNotifications: () => ['auditor', 'notifications'],
};
