"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PARTNER_PERMISSION = exports.PartnerRoleName = exports.PERMISSION_LIST = exports.PartnerPermissions = exports.Permission = void 0;
exports.flattenPermissions = flattenPermissions;
exports.Permission = {
  Organization: {
    VIEW: 'org:view',
    ADD: 'org:add',
    EDIT: 'org:edit',
    DELETE: 'org:delete'
  },
  User: {
    VIEW: 'user:view',
    ADD: 'user:add',
    EDIT: 'user:edit',
    DELETE: 'user:delete'
  },
  Role: {
    VIEW: 'role:view',
    ADD: 'role:add',
    EDIT: 'role:edit',
    DELETE: 'role:delete'
  },
  Topic: {
    VIEW: 'topic:view',
    EDIT: 'topic:edit'
  },
  Storage: {
    VIEW: 'storage:view',
    ADD: 'storage:add',
    DELETE: 'storage:delete'
  },
  Facility: {
    VIEW: 'facility:view',
    ADD: 'facility:add',
    EDIT: 'facility:edit',
    DELETE: 'facility:delete',
    ASSIGN_USER: 'facility:assign_user'
  },
  Partner: {
    VIEW: 'partner:view',
    ADD: 'partner:add',
    EDIT: 'partner:edit',
    DELETE: 'partner:delete'
  },
  Product: {
    VIEW: 'product:view',
    ADD: 'product:add',
    EDIT: 'product:edit',
    DELETE: 'product:delete',
    ASSIGN_PRODUCT: 'product:assign_product'
  },
  Document: {
    VIEW: 'document:view',
    ADD: 'document:add',
    DELETE: 'document:delete'
  },
  EmissionFactor: {
    VIEW: 'emission_factor:view',
    ADD: 'emission_factor:add',
    EDIT: 'emission_factor:edit',
    DELETE: 'emission_factor:delete'
  },
  Equipment: {
    VIEW: 'equipment:view',
    ADD: 'equipment:add',
    EDIT: 'equipment:edit',
    DELETE: 'equipment:delete'
  },
  Note: {
    VIEW: 'note:view',
    ADD: 'note:add',
    EDIT: 'note:edit',
    DELETE: 'note:delete'
  },
  Accounting: {
    VIEW: 'accounting:view'
  },
  Collect: {
    VIEW: 'collect:view',
    CREATE_DCR: 'collect:create_dcr',
    UPDATE_DCR: 'collect:update_dcr',
    SEND_REQUEST_DCR: 'collect:send_request_dcr',
    VIEW_NOTES: 'collect:view_notes',
    ADD_NOTES: 'collect:add_notes',
    VIEW_MENU_COMPLETE: 'collect:view_menu_complete',
    DELETE_DCR: 'collect:delete_dcr'
  },
  Idr: {
    VIEW: 'idr:view',
    VIEW_LIST: 'idr:view_list',
    CREATE: 'idr:create',
    EDIT: 'idr:edit',
    DELETE: 'idr:delete',
    REOPEN: 'idr:reopen',
    SUBMIT: 'idr:submit_review',
    VIEW_REVIEW: 'idr:view_review',
    MANAGE_REVIEW: 'idr:manage_review',
    VIEW_NOTES: 'idr:view_notes',
    ADD_NOTES: 'idr:add_notes'
  },
  PartnerRequest: {
    SEND: 'partner_request:send',
    VIEW: 'partner_request:view',
    EDIT: 'partner_request:edit',
    DELETE: 'partner_request:delete',
    SUBMIT: 'partner_request:submit_review',
    VIEW_NOTES: 'partner_request:view_notes',
    ADD_NOTES: 'partner_request:add_notes'
  },
  Activity: {
    VIEW: 'activity:view',
    CREATE: 'activity:create',
    EDIT: 'activity:edit',
    DELETE: 'activity:delete',
    VIEW_NOTES: 'activity:view_notes',
    ADD_NOTES: 'activity:add_notes',
    VERIFY: 'activity:verify'
  },
  DataContainer: {
    VIEW: 'dc:view',
    EDIT: 'dc:edit'
  },
  Contact: {
    VIEW: 'contact:view',
    ADD: 'contact:add',
    EDIT: 'contact:edit',
    DELETE: 'contact:delete'
  },
  Answer: {
    VIEW: 'answer:view',
    DELETE: 'answer:delete'
  },
  SurveyParticipant: {
    EDIT: 'survey_participant:edit',
    GET_FORMS: 'survey_participant:get_forms'
  },
  Survey: {
    VIEW: 'survey:view',
    ADD: 'survey:add',
    EDIT: 'survey:edit',
    FINALIZE: 'survey:finalize',
    SEND: 'survey:send',
    DELETE: 'survey:delete'
  },
  Currency: {
    VIEW: 'currency:view'
  },
  Report: {
    VIEW: 'report:view',
    CREATE: 'report:create',
    EDIT: 'report:edit',
    DELETE: 'report:delete',
    EXPORT: 'report:export'
  }
};
exports.PartnerPermissions = {
  User: {
    VIEW: 'user:view',
    ADD: 'user:add',
    EDIT: 'user:edit',
    DELETE: 'user:delete'
  },
  Role: {
    VIEW: 'role:view',
    ADD: 'role:add',
    EDIT: 'role:edit',
    DELETE: 'role:delete'
  },
  Topic: {
    VIEW: 'topic:view',
    EDIT: 'topic:edit'
  },
  Storage: {
    VIEW: 'storage:view',
    ADD: 'storage:add',
    DELETE: 'storage:delete'
  },
  Facility: {
    VIEW: 'facility:view',
    ASSIGN_USER: 'facility:assign_user'
  },
  Partner: {
    VIEW: 'partner:view',
    ADD: 'partner:add',
    EDIT: 'partner:edit',
    DELETE: 'partner:delete'
  },
  EmissionFactor: {
    VIEW: 'emission_factor:view'
  },
  Equipment: {
    VIEW: 'equipment:view'
  },
  Idr: {
    VIEW: 'idr:view'
  },
  PartnerRequest: {
    SEND: 'partner_request:send',
    VIEW: 'partner_request:view',
    EDIT: 'partner_request:edit',
    DELETE: 'partner_request:delete',
    SUBMIT: 'partner_request:submit_review',
    VIEW_NOTES: 'partner_request:view_notes',
    ADD_NOTES: 'partner_request:add_notes'
  },
  Activity: {
    VIEW: 'activity:view',
    CREATE: 'activity:create',
    EDIT: 'activity:edit',
    DELETE: 'activity:delete',
    VIEW_NOTES: 'activity:view_notes',
    ADD_NOTES: 'activity:add_notes',
    VERIFY: 'activity:verify'
  },
  Note: {
    VIEW: 'note:view',
    ADD: 'note:add',
    EDIT: 'note:edit',
    DELETE: 'note:delete'
  },
  Product: {
    VIEW: 'product:view',
    ADD: 'product:add',
    EDIT: 'product:edit',
    DELETE: 'product:delete',
    ASSIGN_PRODUCT: 'product:assign_product'
  },
  Document: {
    VIEW: 'document:view',
    ADD: 'document:add',
    DELETE: 'document:delete'
  },
  Currency: {
    VIEW: 'currency:view'
  }
};
function flattenPermissions(obj, prefix = '') {
  return Object.keys(obj).reduce((res, el) => {
    const key = prefix ? `${prefix}:${el}` : el;
    if (typeof obj[el] === 'object') {
      res.push(...flattenPermissions(obj[el], key));
    } else {
      res.push(obj[el]);
    }
    return res;
  }, []);
}
exports.PERMISSION_LIST = flattenPermissions(exports.Permission);
exports.PartnerRoleName = 'partner_admin';
exports.PARTNER_PERMISSION = flattenPermissions(exports.PartnerPermissions);
